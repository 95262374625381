// Define custom font sizes
$display-font-sizes: (
  1: 3rem,
  2: 2.5rem,
  3: 2rem,
  4: 1.5rem,
  5: 1rem,
  6: 0.5rem,
);

$font-size-base: 1.1rem;

// Define custom navbar styles
$dropdown-item-padding-y:           .45rem;
$dropdown-link-hover-bg:            transparent;
$navbar-nav-link-padding-x:         .25rem;
$nav-pills-border-radius:           50rem;
