// 1. Global preferences and custom variables
@import "enable_options"; // Global CSS preferences
@import "hugo:vars";
@import "colors"; // Custom color definitions
@import "custom_variables"; // Custom Bootstrap variable overrides
@import "custom_functions"; // Custom SCSS functions

// 2. Import Bootstrap
@import "bootstrap/scss/bootstrap";

// 3. Your custom styles
@import "theme"; // Custom theme styles
@import "styles"; // Other custom styles
