// /* Custom page CSS
// -------------------------------------------------- */

.navbar a {
  font-size: 1.1rem;
}

.bg-footer-top>div {
  --bs-bg-opacity: 1;
  background-color: rgba($gray-800,var(--bs-bg-opacity)) !important;
}

.bg-footer-bottom {
  --bs-bg-opacity: 1;
  background-color: rgba($gray-900,var(--bs-bg-opacity)) !important;
}

.banner-section {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.banner-section {
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7), -2px -2px 10px rgba(255, 255, 255, 0.3);
}

footer .logo svg {
  fill: $white;
  height: 60px;
  margin-bottom: 10px;
}

footer .logo svg path:not(:first-child)  {
  fill: $white;
}
